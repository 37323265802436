import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'

import {
  formattedScore,
  dateMe,
  getMyTrainingScoring,
  displayTrackName,
  getTrack,
  urlForTraining,
} from '../../Helpers'
import { SocialContext } from '../../context/socialContext'
import { useOpen10kUserComment } from '../../routes/parts-for-10k/10kHelpers'

import { extractMentions } from '../elements/AtomMarkup'
import Button from '../elements/Button'
import ClampedText from './ClampedText'
import Icon from '../elements/Icon'
import Markdown from '../elements/Markdown'
import RowMarker from '../elements/RowMarker'
import UserKitCommentLike from './UserKitCommentLike'

type TrainingJournalRowProps = {
  row: Object,
  openManualEdit?: Object,
  action?: () => void,
  disabled?: Boolean,
}

TrainingJournalRow.defaultProps = {
  openManualEdit: undefined,
  disabled: false,
  action: undefined,
}

function boldMatches(
  str,
  substrs,
  markupStart = '<strong>',
  markupEnd = '</strong>',
) {
  const pattern = `(${substrs.join('|')})`
  const regexp = new RegExp(pattern, 'ig') // ignore case (optional) and match all
  const replaceMask = `${markupStart}$1${markupEnd}`
  return str.replaceAll(regexp, replaceMask)
}

function TrainingJournalRow(props: TrainingJournalRowProps) {
  const { row, openManualEdit, disabled, action } = props
  const { openComments } = useContext(SocialContext)

  const open10kUserComment = useOpen10kUserComment()
  const track = getTrack(row?.track)

  const rowAction = (kind, actionFunc, content, rowDisabled, classes) => {
    if (rowDisabled) return content
    switch (kind) {
      case 'link':
        return (
          <Link to={actionFunc} className={classes}>
            {content}
          </Link>
        )
      case 'button':
      default:
        return (
          <Button
            label={content}
            kind="text"
            disabled={false}
            onClick={actionFunc}
            cnames={classes !== undefined ? classes : 'text--bold'}
          />
        )
    }
  }
  const overrideAction = row?.metadata?.tags?.includes('persist_nutrition')
  const actionType = overrideAction ? 'link' : 'button'

  const getFinalAction = () => {
    if (overrideAction) return () => urlForTraining(row)
    if (track?.noTraining) return () => openManualEdit()
    if (Object.is(row.track, 'challenge'))
      return () =>
        open10kUserComment({
          ...row,
          id: row.userId,
        })
    if (action) {
      return () => action(row)
    }
    return () => openComments(row)
  }

  const finalAction = getFinalAction()

  const entryTitle = row?.training?.title
    ? `${row?.training?.title} ${
        row?.training?.subtitle ? row?.training?.subtitle : ''
      }`
    : `${row?.title || displayTrackName(row?.track) || ''} ${
        track?.showDateInTitle ? dateMe(row?.date, 'title') : ''
      }`

  const realScoreText = row.completedOnly ? (
    <Icon name="check-circle" type="fas" size="lg" fw={false} />
  ) : (
    <b>
      {row.beatCap || track?.noCap
        ? formattedScore(row.score, getMyTrainingScoring(row))
        : row.tiebreak}
    </b>
  )
  const isDraft = row.status === 'draft'
  const showCommentsLikes = !overrideAction && !isDraft
  const draftText = <span className="fg--danger text--caps">DRAFT</span>
  const scoreText = isDraft ? draftText : realScoreText

  const dateForMarker = row.track === 'manual' ? row.date : row.created
  const rowMarker = (
    <RowMarker
      line1={dayjs(dateForMarker).format('MMM')}
      line2={dayjs(dateForMarker).format('DD')}
    />
  )

  return (
    <li className={disabled ? 'journal--row ghost' : 'journal--row'}>
      {actionType === 'button' ? (
        <Button
          kind="secret"
          onClick={finalAction}
          cnames="background-button"
          ariaLabel="Open"
          disabled={disabled}
        />
      ) : (
        <Link to={finalAction} className="background-button" />
      )}

      {rowAction(actionType, finalAction, rowMarker, disabled)}

      <div className="journal--row--details">
        <h4 className="text--caps">
          {rowAction(actionType, finalAction, entryTitle, disabled)}
        </h4>

        <UserKitCommentLike
          kit={row?.kit}
          level={row?.level}
          rank={row?.rank}
          total={row?.total}
          showRank
          showComments={showCommentsLikes}
          showLikes={showCommentsLikes}
          commentCount={row?.commentCount?.toString()}
          likeCount={row?.likeCount?.toString()}
          openComments={disabled ? () => {} : finalAction}
          toggleLike={() => {}}
          isLikeUser={row?.isLikeUser}
          cnames="journal--row--meta"
        />

        {row.searchData?.value && (
          <>
            {row.searchData?.key === 'userTraining.caption'
              ? rowAction(
                  actionType,
                  finalAction,
                  <Markdown forbiddenElements={['ol']} className="text--small">
                    {boldMatches(
                      extractMentions(row.searchData?.value)[0],
                      row.searchData?.terms,
                    )}
                  </Markdown>,
                  disabled,
                  '',
                )
              : rowAction(
                  actionType,
                  finalAction,
                  <Markdown forbiddenElements={['ol']} className="text--small">
                    {boldMatches(row.searchData?.value, row.searchData?.terms)}
                  </Markdown>,
                  disabled,
                  '',
                )}
            {row.searchData?.sectionNote && (
              <Markdown
                className="ish text--small font--italic"
                forbiddenElements={['ol']}
              >
                {boldMatches(
                  `&quot;${row.searchData?.sectionNote}&quot;`,
                  row.searchData?.terms,
                )}
              </Markdown>
            )}
          </>
        )}

        {row.notes && !row.searchData?.value && (
          <ClampedText
            cnames="journal--row--comment ish font--italic text--small"
            text={`"${row.notes}"`}
          />
        )}
      </div>

      <div className="is-flex--column">
        {rowAction(
          actionType,
          finalAction,
          scoreText,
          disabled,

          'journal--row--score',
        )}
      </div>
    </li>
  )
}

export default TrainingJournalRow
