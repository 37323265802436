import React, { useContext } from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom'
import { Helmet } from 'react-helmet-async'

import { AtomContext, useProfile } from './context/atomContext'
import Account from './routes/Account'
import TermsOfUse from './routes/TermsOfUse'
import Onboarding from './routes/Onboarding'
import Leaderboard from './routes/Leaderboard'
import Leaderboard10k from './routes/Leaderboard10k'
import WorkshopLeaderboard from './routes/WorkshopLeaderboard'
import Dashboard from './routes/Dashboard'
import Training from './routes/Training'
import Training10k from './routes/Training10k'
import WorkshopTraining from './routes/WorkshopTraining'
import TrainingJournal from './routes/TrainingJournal'
import EditProfile from './routes/EditProfile'
import SimpleEditProfile from './routes/SimpleEditProfile'
import AffiliateOverview from './routes/AffiliateOverview'
import VideoLibrary from './routes/VideoLibrary'
import AffiliateStaff from './routes/AffiliateStaff'
import Resources from './routes/Resources'
import Tour from './routes/Tour'
import Workshops from './routes/Workshops'

import { useSupport } from './hooks/SupportHooks'
import Nutrition from './routes/Nutrition'

export default function AtomRouter() {
  const context = useContext(AtomContext)
  const { status, isStatusLoading } = context
  const { data: profile } = useProfile()
  const { supportTracks } = useSupport()

  const intercomTracks = supportTracks?.filter(
    t => t?.support?.kind === 'intercom',
  )
  const getIntercomMetadata = () => {
    if (!intercomTracks?.[0]?.supportUserData?.metadata) return ''
    const keys = Object.keys(intercomTracks[0].supportUserData.metadata)
    let md = ''
    for (let i = 0; i < keys.length; i += 1) {
      md += `${keys[i]}: "${
        intercomTracks[0].supportUserData.metadata[keys[i]]
      }",\n`
    }
    return md
  }

  if (!isStatusLoading) {
    if (!status?.loggedIn) {
      const loginExtras =
        typeof window !== 'undefined'
          ? `&onSuccess=${window?.location?.protocol}//${
              window?.location?.hostname
            }${window?.location?.port ? `:${window?.location?.port}` : ''}`
          : ''

      window.location = `/api/authenticate/login?view=login${loginExtras}`
    }
  }

  // TODO probably want to show more than a blank here
  if (!status?.loggedIn) return ''

  const lastProgram = localStorage.getItem(`lastViewedProgram`)
  const program =
    lastProgram && profile?.value?.subscriptions?.[lastProgram]
      ? lastProgram
      : undefined

  const needsProfile =
    status?.profileRequirements !== undefined &&
    status?.profileRequirements !== null &&
    window.location.pathname !== '/app/simpleprofile'

  const profileNeeds = Object.keys(status?.profileRequirements || [])
  const showSimpleProfile = () => {
    if (!needsProfile) return false
    // if some value in profile needs isn't first/last we have to go to simple profile
    if (profileNeeds.some(val => val !== 'firstName' && val !== 'lastName'))
      return true
    // In the case of only first/last needing updating we will depend on their routes to show it so return false
    return false
  }

  return (
    <>
      <Helmet>
        {intercomTracks?.length > 0 && profile?.user?.id && (
          <>
            <script>
              {`window.intercomSettings = {
    api_base: "https://api-iam.intercom.io",
    app_id: "${intercomTracks[0].support.id}",
    avatar: {
      "type": "avatar", 
      "image_url" :"${profile?.value?.profileImage}"
    },
    user_id: "${profile?.user?.id}",
    ${
      intercomTracks[0].supportUserData.email
        ? `email: "${intercomTracks[0].supportUserData.email}",`
        : ''
    }
    ${getIntercomMetadata()}
    hide_default_launcher: true,
  };`}

              {/* ${
      intercomTracks[0].supportUserData.userId
        ? `user_hash: "${intercomTracks[0].supportUserData.userId}",`
        : ''
    } */}
            </script>
            <script>
              {`(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/ell747o8';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
`}
            </script>
            <script>
              {`
              window.Intercom('onUnreadCountChange', unreadCount => {
          const event = new CustomEvent('atomSupportUnreadCountChange', {
            detail: {
              unreadCount: unreadCount
            }
          });
          window.dispatchEvent(event);
          zE(function () {
            window.zE.hide()
          })    
          })

`}
            </script>
          </>
        )}
      </Helmet>

      <Router basename="/app">
        {/* <Switch> looks through its child <Route>s and
              renders the first one that matches the current URL. */}
        {showSimpleProfile() ? (
          <SimpleEditProfile />
        ) : (
          <Switch>
            <Route path="/terms-of-use">
              <TermsOfUse />
            </Route>
            <Route path="/onboarding">
              <Onboarding />
            </Route>
            <Route
              path="/:track(element|atomic|affiliate|strength|persist)/training"
              render={({ match }) => <Training key={match?.params?.track} />}
            />
            <Route path="/training">
              <Training />
            </Route>
            <Route path="/events/10k/leaderboard">
              <Leaderboard10k />
            </Route>
            <Route path="/events/10k">
              <Training10k />
            </Route>
            <Route path="/journal">
              <TrainingJournal />
            </Route>
            <Route path="/library">
              <VideoLibrary />
            </Route>
            <Route path="/:track(persist)/nutrition">
              <Nutrition />
            </Route>
            <Route
              path="/:track(element|atomic|affiliate|strength|persist)/leaderboard"
              render={({ match }) => <Leaderboard key={match?.params?.track} />}
            />
            <Route
              path="/:track(element|atomic|affiliate|strength|persist)/tour"
              render={({ match }) => <Tour key={match?.params?.track} />}
            />
            <Route path="/tour">
              <Tour />
            </Route>
            <Route path="/leaderboard">
              <Leaderboard />
            </Route>
            <Route path="/dashboard">
              {needsProfile ? <SimpleEditProfile /> : <Dashboard />}
            </Route>
            <Route path="/profile">
              <EditProfile />
            </Route>
            <Route path="/account">
              <Account />
            </Route>
            <Route path="/simpleprofile">
              <SimpleEditProfile />
            </Route>
            <Route
              path="/workshops/:workshopID/training"
              render={({ match }) => (
                <WorkshopTraining key={match?.params?.workshopID} />
              )}
            />
            <Route
              path="/workshops/:workshopID/leaderboard"
              render={({ match }) => (
                <WorkshopLeaderboard key={match?.params?.workshopID} />
              )}
            />
            <Route path="/workshops">
              <Workshops />
            </Route>

            <Route path="/affiliate/overview">
              <AffiliateOverview />
            </Route>
            <Route path="/affiliate/staff">
              <AffiliateStaff />
            </Route>
            <Route path="/affiliate/resources">
              <Resources />
            </Route>
            <Route path="/resources">
              <Resources />
            </Route>
            <Route
              path="/:track(affiliate)/coaching"
              render={() => (
                <Training key="affiliate-coaches" showCoachesView />
              )}
            />
            <Route path="/">
              {needsProfile && <SimpleEditProfile />}
              {!needsProfile && program && (
                <Redirect to={`/${program}/training`} />
              )}
              {!needsProfile && !program && <Dashboard />}
            </Route>
          </Switch>
        )}
      </Router>
    </>
  )
}
