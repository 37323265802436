import React, { useCallback, useContext, useEffect, useState } from 'react'
import dayjs from 'dayjs'

import TextField from '@mui/material/TextField'

import { SwiperSlide } from 'swiper/react'

import { printClasses } from '../../Helpers'
import { displayTitle, movementOptionForLevel } from './TrainingHelpers'
import MovementDetails from './MovementDetails'
import DashboardSlider from '../../components/molecules/DashboardSlider'
import DetailsBlock from '../../components/structures/DetailsBlock'
import Markdown from '../../components/elements/Markdown'
import MovementDemoSlide from './MovementDemoSlide'
import Icon from '../../components/elements/Icon'
import Button from '../../components/elements/Button'
import RowMarker from '../../components/elements/RowMarker'

import { SocialContext } from '../../context/socialContext'
import { useIsPermission } from '../../hooks/FeatureHooks'

type WorkoutPartProps = {
  workout: Object,
  selectedItems: Object,
  selectLevel: Object,
  defaultLevel: String,
  showDemo: any,
  isInteractive?: Boolean,
  isLocked?: Boolean,
  lockedAction?: () => void,
  cnames?: string,
  sectionNotes?: string,
  setSectionNotes: Object,
  displayEditScoreForm: Object,
  id: string,
}

WorkoutPart.defaultProps = {
  isInteractive: true,
  isLocked: false,
  lockedAction: undefined,
  cnames: null,
  sectionNotes: '',
}

function WorkoutPart(props: WorkoutPartProps) {
  const {
    workout,
    selectedItems,
    selectLevel,
    defaultLevel,
    showDemo,
    isInteractive,
    isLocked,
    lockedAction,
    cnames,
    sectionNotes,
    setSectionNotes,
    displayEditScoreForm,
    id,
  } = props

  const { openComments } = useContext(SocialContext)
  const isCompareToVisible = useIsPermission(['beta', 'compareToDate'])
  const [playDemos, setPlayDemos] = useState(false)

  const [loadMovements, setLoadMovements] = useState(false)

  useEffect(() => {
    // If we are too quick in unloading and loading videoplayers brightcove hits errors
    // in async calls that it doesn't handle in the unload case. This gross solution works around
    // the issue
    const loading = setTimeout(() => {
      setLoadMovements(true)
    }, 500)

    return () => clearTimeout(loading)
  }, [])

  const notesChanged = useCallback(
    event => {
      setSectionNotes(event.target.value)
    },
    [setSectionNotes],
  )

  const notesTextClasses = [
    'rpm-input rpm-input--textarea',
    'rpm-input--field',
    'form-area--section-notes',
    'training--format',
  ]
  const allowNotes = workout?.format?.l1?.allowUserNotes

  const classes = ['training--movement', cnames]

  const lockClasses = [isLocked && 'is-locked', !isInteractive && 'is-static']

  return (
    <div className={printClasses(classes)}>
      {workout?.format?.l1?.header && (
        <span className="is-block rpm-description training--section-header">
          <Markdown>{workout.format.l1.header}</Markdown>
        </span>
      )}

      {workout.format && workout.format.l1.title && (
        <h4 className="training--format text--caps">
          {displayTitle(
            workout.format.l1,
            null,
            'format',
            null,
            null,
            Markdown,
          )}
        </h4>
      )}

      <div className={printClasses(lockClasses)}>
        {workout.movement.map((movement, index) => (
          <MovementDetails
            index={index}
            movement={movement}
            selectedItem={selectedItems && selectedItems[index]}
            defaultLevel={defaultLevel}
            onClick={selectLevel}
            showDemo={showDemo}
            isInteractive={isInteractive}
            isLocked={isLocked}
            lockedAction={lockedAction}
            key={String(`${index}-${movement.options[0].title}`)}
          />
        ))}
      </div>

      <div style={{ margin: '0 var(--space-bump)' }}>
        {loadMovements && workout.movement.length > 0 && (
          <DashboardSlider
            defaultItem={0}
            className="dashboard-slider-"
            spaceBetween={16}
            slidesPerView={1.5}
            loop={false}
            centeredSlides={false}
            navigation={false}
            rewind={false}
            key={id}
            onSliderMove={() => setPlayDemos(false)}
            onClick={swip => {
              if (playDemos && swip.activeIndex === swip.clickedIndex)
                showDemo({
                  demoVideoId: swip.slides[swip.clickedIndex].dataset.videoId,
                  title: swip.slides[swip.clickedIndex].dataset.videoTitle,
                })
              swip.slideTo(swip.clickedIndex)
              setPlayDemos(true)
            }}
          >
            {workout.movement.map((movement, index) => {
              const selItem = selectedItems && selectedItems[index]
              const selectedOption = movementOptionForLevel(
                movement,
                selItem || { level: defaultLevel },
              )
              const visibleOption =
                selectedOption ||
                movementOptionForLevel(movement, {
                  level: selItem?.levels[0] || defaultLevel,
                })

              if (!visibleOption.demoVideoId) return

              return (
                <SwiperSlide
                  key={String(`${id}-${visibleOption.demovideoId}-${index}`)}
                  style={{
                    borderRadius: 'var(--radius-bump)',
                    overflow: 'hidden',
                    width: 'var(--dashboard-slider--slide-width)',
                  }}
                  data-video-id={visibleOption.demoVideoId}
                  data-video-title={visibleOption.demoVideo.title}
                >
                  <MovementDemoSlide
                    videoId={visibleOption.demoVideoId}
                    id={String(`${visibleOption.demoVideoId}-${index}`)}
                    play={playDemos}
                    setPlay={setPlayDemos}
                    videoData={visibleOption.demoVideo}
                  />
                </SwiperSlide>
              )
            })}
          </DashboardSlider>
        )}
      </div>

      {workout?.format?.l1?.notes && (
        <div>
          <DetailsBlock collapsible cnames="training--format">
            <Markdown>{workout.format.l1.notes}</Markdown>
          </DetailsBlock>
        </div>
      )}

      {isCompareToVisible && workout?.compareToDate?.length > 0 && (
        <>
          <h4 className="training--format text--caps">
            <span className="training--format--title">Compare to:</span>
          </h4>
          <div>
            <div className="rpm-details-block training--format flex--auto-gap">
              {workout.compareToDate.map(comparedWorkout => (
                <Button
                  key={`${comparedWorkout.date}-${comparedWorkout.track}-${comparedWorkout.kit}`}
                  onClick={() => openComments(comparedWorkout)}
                  kind="secret"
                >
                  <RowMarker
                    line1={dayjs(comparedWorkout.date).format('MMM')}
                    line2={dayjs(comparedWorkout.date).format('DD')}
                  />
                </Button>
              ))}
            </div>
          </div>
        </>
      )}

      {workout.movement
        .filter(m => m.allowFormReview)
        .map((movement, index) => (
          <div
            className="training--format training--form-review"
            key={`${index}-${movement.options[0].title}`.toString('base64')}
          >
            <div className="flex--auto-spread">
              <h4 className="text--caps">
                <Icon name="video-plus" type="far" cnames="form-icon" />
                Form Review
              </h4>
              <Button
                kind="link"
                cnames="training--form-review--button"
                onClick={() => undefined}
              >
                <small>
                  <b>Upload Video</b>
                </small>
              </Button>
            </div>
            <div className="training--form-review--details">
              {
                movementOptionForLevel(
                  movement,
                  selectedItems?.[index] || { level: defaultLevel },
                ).title
              }
            </div>
          </div>
        ))}

      {allowNotes && (!isLocked || (isLocked && sectionNotes)) && (
        <div className="training--format is-flex flex--auto-spread">
          {/* <Textarea
            name="sectionNotesInput"
            placeholder="Section Notes (private)"
            value={sectionNotes}
            changed={isLocked ? () => {} : notesChanged}
            cnames={printClasses(notesTextClasses)}
            rows={1}
            readOnly={isLocked}
          /> */}
          {!isLocked && (
            <TextField
              id={`${id}--sectionNotes`}
              placeholder="Section Notes (private)"
              maxRows={4}
              multiline
              hiddenLabel
              variant="filled"
              value={sectionNotes}
              onChange={isLocked ? () => {} : notesChanged}
              cnames={printClasses(notesTextClasses)}
              readOnly={isLocked}
              fullWidth
              sx={{
                margin: '1rem 1.322rem',
                '& .MuiFilledInput-root:hover': {
                  backgroundColor: 'white',
                },
                '& .MuiFilledInput-root:before': {
                  borderBottom: 'none',
                },
                '& .MuiFilledInput-root:hover:before': {
                  borderBottom: '1px solid rgba(0, 0, 0, 0.5)',
                },
                '& .MuiFilledInput-root.Mui-focused': {
                  backgroundColor: 'white',
                },
                '.MuiInputBase-root': {
                  backgroundColor: 'white',
                  border: '0.07em solid var(--brand-base)',
                },
                '.Mui-focused': {
                  backgroundColor: 'white',
                  boxShadow: '0 0 0 1px var(--brand-base) inset',
                },
                '.Mui-focused:after': {
                  borderBottomColor: 'var(--brand-base)',
                },
              }}
            />
          )}
          {/* eslint-disable jsx-a11y/label-has-associated-control */}

          {isLocked && (
            <label
              htmlFor={`${id}--sectionNotes`}
              className={printClasses([
                'rpm-input',
                'rpm-input--field',
                `rpm-input--textarea`,
                'form-area--section-notes',
                'training--format',
              ])}
            >
              <textarea
                id={`${id}--sectionNotes`}
                className="rpm-input--element"
                value={sectionNotes}
                readOnly
                rows={sectionNotes.split('\n').length || 1}
              />
            </label>
          )}
          {/* eslint-enable jsx-a11y/label-has-associated-control */}

          {allowNotes && isLocked && sectionNotes && (
            <Button
              kind="icon"
              cnames="training--format--notes--button"
              onClick={displayEditScoreForm}
            >
              <Icon name="pen-to-square" type="far" />
            </Button>
          )}
        </div>
      )}
      {false && allowNotes && isLocked && sectionNotes && (
        <div className="training--format training--format--notes is-flex flex--auto-spread">
          <Markdown allowedElements={['p']}>{sectionNotes}</Markdown>
          <Button
            kind="icon"
            cnames="training--format--notes--button"
            onClick={displayEditScoreForm}
          >
            <Icon name="pen-to-square" type="far" />
          </Button>
        </div>
      )}
    </div>
  )
}

export default WorkoutPart
