import React, { useState } from 'react'

import { printClasses } from '../../Helpers'
import { displayTitle, movementOptionForLevel } from './TrainingHelpers'

import Button from '../../components/elements/Button'
import Icon from '../../components/elements/Icon'
import Markdown from '../../components/elements/Markdown'

type MovementDetailsProps = {
  index: String,
  movement: Object,
  selectedItem: String,
  defaultLevel: String,
  onClick: (index: string, element: any) => {},
  showDemo: any,
  isNested: Boolean,
  isInteractive: Boolean,
  isLocked: Boolean,
  lockedAction?: () => void,
}

MovementDetails.defaultProps = {
  lockedAction: undefined,
}

function MovementDetails(props: MovementDetailsProps) {
  const {
    index,
    movement,
    selectedItem,
    defaultLevel,
    onClick,
    showDemo,
    isNested,
    isInteractive,
    isLocked,
    lockedAction,
  } = props

  const [isOpenDetails, setIsOpenDetails] = useState(false)

  const selectedOption = movementOptionForLevel(
    movement,
    selectedItem || { level: defaultLevel },
  )

  const visibleOption =
    selectedOption ||
    movementOptionForLevel(movement, {
      level: selectedItem?.levels[0] || defaultLevel,
    })
  const hasManyOptions = movement?.options?.length > 1

  function toggleOpen(event, time: number = 0) {
    event?.preventDefault()
    if (!hasManyOptions) return
    setTimeout(() => {
      setIsOpenDetails(!isOpenDetails)
    }, time)
  }

  const handleDemoVideoCue = (event, video) => {
    event.stopPropagation()

    if (video?.demoVideoId) {
      showDemo(video)
    }
  }

  const demoCue = video => (
    <Button
      onClick={event => handleDemoVideoCue(event, video)}
      kind="secret"
      cnames="demo-cue"
      icon={{
        name: 'circle-info',
        type: 'far',
      }}
    />
  )

  const selectedMovement = () => (
    <div className="training--movement--title">
      <div className="summary-title">
        {displayTitle(
          visibleOption,
          visibleOption?.levels,
          'movement',
          visibleOption?.levels?.length === 4,
          movement.allowFormReview,
          Markdown,
          ['i', 'em', 'strong', 'b'],
        )}
      </div>
      {isInteractive && hasManyOptions && (
        <div className="summary-marker">
          <Icon type="far" name="angle-right" cnames="marker" />
        </div>
      )}
      {!hasManyOptions && visibleOption?.demoVideoId && demoCue(visibleOption)}
      {!hasManyOptions && !visibleOption?.demoVideoId && (
        <div style={{ minWidth: '1rem' }} />
      )}
    </div>
  )

  const classes = ['training--movement--details', isNested && 'is-nested']
  const handleClick = event => {
    if (hasManyOptions) {
      toggleOpen(event)
    } else if (isNested) {
      onClick(index, {
        id: movement.options[0].id,
        level: movement.options[0].levels[0],
      })
    } else {
      toggleOpen(event)
    }
  }
  const handleKeyDown = event => {
    if (event.keyCode === 13) {
      handleClick(event)
    }
  }

  return (
    <>
      <details
        className={printClasses(classes)}
        open={isInteractive && isOpenDetails}
      >
        {/* eslint-disable jsx-a11y/no-static-element-interactions  */}
        <summary
          className="training--movement--summary"
          onClick={handleClick}
          onKeyDown={handleKeyDown}
        >
          {selectedMovement()}
        </summary>
        {/* eslint-enable jsx-a11y/no-static-element-interactions  */}
        {hasManyOptions && (
          <ul className="training--movement--content">
            {movement.options?.map((option, optIndex) => {
              const selected =
                (option.id === undefined &&
                  selectedOption &&
                  option.levels?.includes(
                    selectedOption?.levels[0] || defaultLevel,
                  )) ||
                (option.id !== undefined && option.id === selectedOption?.id) ||
                option.options?.find(item => item.id === selectedOption?.id)

              const previousVideoId =
                optIndex > 0 && movement.options[optIndex - 1].demoVideoId

              const check = selected ? (
                <Icon
                  type="far"
                  name={isLocked ? 'long-arrow-right' : 'check'}
                  cnames="brand-fg"
                />
              ) : (
                <span className="spacer" />
              )

              const hasOptions = option.options !== undefined

              const liClasses = [
                selected && 'is-selected',
                hasOptions && 'has-options',
              ]

              return (
                <li
                  className={printClasses(liClasses)}
                  key={String(
                    `${optIndex}-${
                      option.levels?.join(' ') || option.options[0].id
                    }`,
                  )}
                >
                  {check}

                  {hasOptions && (
                    <MovementDetails
                      index={index}
                      movement={option}
                      selectedItem={selectedOption}
                      defaultLevel={defaultLevel}
                      onClick={(selectedIndex, clickedOption) => {
                        toggleOpen(undefined, 600)
                        onClick(selectedIndex, clickedOption)
                      }}
                      showDemo={showDemo}
                      key={String(`${optIndex}-${option.options[0].id}`)}
                      isInteractive={isInteractive}
                      isLocked={isLocked}
                      isNested
                    />
                  )}

                  {!hasOptions && (
                    <>
                      {isLocked ? (
                        <Button
                          kind="text"
                          label={displayTitle(
                            option,
                            option.levels,
                            null,
                            null,
                            null,
                            Markdown,
                            ['i', 'em', 'strong', 'b'],
                          )}
                          onClick={lockedAction}
                        />
                      ) : (
                        <Button
                          kind="text"
                          label={displayTitle(
                            option,
                            option.levels,
                            null,
                            null,
                            null,
                            Markdown,
                            ['i', 'em', 'strong', 'b'],
                          )}
                          onClick={event => {
                            toggleOpen(event, 600)
                            onClick(index, {
                              id: option.id,
                              level: option.levels[0],
                            })
                          }}
                        />
                      )}

                      {isInteractive &&
                        (selected ||
                          !Object.is(option.demoVideoId, previousVideoId)) &&
                        option.demoVideoId &&
                        demoCue(option)}
                    </>
                  )}
                </li>
              )
            })}
          </ul>
        )}
      </details>
      {/* <video /> */}
    </>
  )
}

export default MovementDetails
