import React from 'react'

import DetailsBlock from '../../components/structures/DetailsBlock'
import Markdown from '../../components/elements/Markdown'
import SubmitScore from '../../components/screens/SubmitScore'

type ShowSubmitScoreProps = {
  visibleSelectedKit: Object,
  track?: string,
  type: string,
  timecap?: Number,
  date: string,
  hasLeaderboard?: Boolean,
  mentions: Object,
  scoreKeyPath: string,
}

ShowSubmitScore.defaultProps = {
  track: 'atomic',
  timecap: null,
  hasLeaderboard: true,
}

function ShowSubmitScore(props: ShowSubmitScoreProps) {
  const {
    visibleSelectedKit,
    track,
    type,
    timecap,
    date,
    hasLeaderboard,
    mentions,
    scoreKeyPath,
  } = props

  return (
    <>
      {visibleSelectedKit?.scoringNotes && (
        <DetailsBlock
          label="Scoring"
          collapsible={false}
          cnames="rpm-description training--info-block training--scoring-notes"
        >
          <Markdown>{visibleSelectedKit.scoringNotes}</Markdown>
        </DetailsBlock>
      )}
      <div className="rpm-submit-score">
        <SubmitScore
          track={track}
          date={date}
          type={type}
          timecap={timecap}
          hasLeaderboard={hasLeaderboard}
          mentions={mentions}
          scoreKeyPath={scoreKeyPath}
        />
      </div>
    </>
  )
}

export default ShowSubmitScore
