import React from 'react'

import { movementOptionForLevel } from './TrainingHelpers'
import { glueThese } from '../../Helpers'

import MarkdownRaw from '../../components/elements/MarkdownRaw'

type WorkoutOverlayProps = {
  activeWorkout: Object,
  workoutFormat: Object,
  movementSelections: Object,
  workoutHeading?: String,
  defaultLevel?: string,
  sectionNotes?: Object,
  workoutNotes?: string,
}

WorkoutOverlay.defaultProps = {
  defaultLevel: 'l1',
  workoutHeading: 'Workout',
  sectionNotes: undefined,
  workoutNotes: undefined,
}

function WorkoutOverlay(props: WorkoutOverlayProps) {
  const {
    activeWorkout,
    workoutFormat,
    movementSelections,
    workoutHeading,
    defaultLevel,
    sectionNotes,
    workoutNotes,
  } = props

  const selectionForPartMovement = (partIndex, movementIndex) => {
    if (
      !movementSelections ||
      !movementSelections[partIndex] ||
      !movementSelections[partIndex][movementIndex]
    ) {
      return { level: defaultLevel }
    }

    return movementSelections[partIndex][movementIndex]
  }

  return (
    <>
      {workoutHeading && <h2>{workoutHeading}</h2>}
      {workoutFormat?.title && <h3>{workoutFormat.title}</h3>}
      {workoutFormat?.subtitle && <h3>{workoutFormat.subtitle}</h3>}

      <ul>
        {activeWorkout.map((part, partIndex) => (
          <li key={String(`${part.format.l1.title}-${partIndex}`)}>
            <h2>
              <MarkdownRaw>
                {part?.format?.l1?.header && part.format.l1.header}
              </MarkdownRaw>
            </h2>
            {part?.format?.l1?.title && (
              <h3>
                <MarkdownRaw>{part.format.l1.title}</MarkdownRaw>
              </h3>
            )}
            {part?.format?.l1?.subtitle && (
              <MarkdownRaw>{part.format.l1.subtitle}</MarkdownRaw>
            )}

            <ul>
              {part.movement.map((movement, movementIndex) => {
                const option = movementOptionForLevel(
                  movement,
                  selectionForPartMovement(partIndex, movementIndex),
                )

                return (
                  <li
                    key={String(
                      `${movement.options[0].title}-${movementIndex}`,
                    )}
                  >
                    <MarkdownRaw allowedElements={['i', 'em', 'strong', 'b']}>
                      {option.title}
                    </MarkdownRaw>

                    {option.levels.length !== 4 && (
                      <sup className="text--caps">
                        {glueThese(option.levels)}
                      </sup>
                    )}
                  </li>
                )
              })}
            </ul>
            {sectionNotes && sectionNotes[partIndex] && (
              <h4 className="fg--brand text--section-notes text--italic">
                &quot;{sectionNotes[partIndex]}&quot;
              </h4>
            )}
          </li>
        ))}
        {workoutNotes && (
          <li>
            <h4 className="fg--brand text--section-notes text--italic">
              &quot;{workoutNotes}&quot;
            </h4>
          </li>
        )}
      </ul>
    </>
  )
}

export default WorkoutOverlay
