import React, { useState } from 'react'
import { useSubmitScore } from '../../context/atomContext'

import IconText from '../../components/elements/IconText'
import InputGroup from '../../components/inputs/InputGroup'
import Textarea from '../../components/inputs/Textarea'
import TextField from '../../components/inputs/TextField'

import Alert from '../../components/elements/Alert'
import Button from '../../components/elements/Button'

type SubmitScoreProps = {
  trainingId: Number,
  date: string,
  totals?: Object,
  whenSubmit?: any,
}

SubmitScore.defaultProps = {
  totals: {},
  whenSubmit: null,
}

function SubmitScore(props: SubmitScoreProps) {
  const { trainingId, date, totals, whenSubmit } = props

  const [singles, setSingles] = useState(totals?.singles || '')
  const [doubles, setDoubles] = useState(totals?.doubles || '')
  const [comment, setComment] = useState('')

  const postScoreSubmission = useSubmitScore()

  const errors =
    postScoreSubmission.isError && postScoreSubmission.error?.data
      ? postScoreSubmission.error.data
      : {}

  const submitScore = () => {
    const postData = {
      trainingId,
      date,
      comment,
      singles,
      doubles,
      track: 'event/challenge',
    }

    postScoreSubmission.mutate(postData, {
      onSuccess: () => {
        const boom =
          totals?.allTimeCombined < 10000 &&
          totals?.allTimeCombined -
            parseInt(totals?.singles || 0, 10) -
            parseInt(totals?.doubles || 0, 10) +
            parseInt(singles || 0, 10) +
            parseInt(doubles || 0, 10) >=
            10000

        whenSubmit(boom)
      },
      onError: anError => {
        console.log(anError)
      },
    })
  }

  const allowSubmit = () => true

  return (
    <>
      {postScoreSubmission.isError && (
        <Alert type="error" message="Please correct any issues..." />
      )}

      <section className="rpm-dialog--section rpm-dialog--section--content">
        <InputGroup columns="1fr 1fr" gap="1em">
          <TextField
            id="doubles"
            name="doubles"
            label={
              <IconText
                icon={{ name: 'chevrons-up', type: 'fal', cnames: 'ish' }}
                text="Double-Unders"
                cnames=""
              />
            }
            placeholder=""
            type="tel"
            maxLength={5}
            value={doubles}
            changed={event => setDoubles(event.target.value)}
            cnames="form-area--numbers"
            error={errors?.doubles !== undefined}
          />

          <TextField
            id="single"
            name="single"
            label={
              <IconText
                icon={{ name: 'chevron-up', type: 'fal', cnames: 'ish' }}
                text="Single-Unders"
                cnames=""
              />
            }
            placeholder=""
            type="tel"
            maxLength={5}
            value={singles}
            changed={event => setSingles(event.target.value)}
            cnames="form-area--numbers"
            error={errors?.singles !== undefined}
          />
        </InputGroup>

        <p>
          <Textarea
            id="commentInput"
            name="commentInput"
            label="Comment (public)"
            rows={1}
            value={comment}
            changed={event => setComment(event.target.value)}
            cnames="form-area--comment"
          />
        </p>
      </section>

      <section className="rpm-dialog--section rpm-dialog--section--buttons">
        <Button
          label="Save Jumps"
          kind="primary"
          disabled={!allowSubmit()}
          onClick={() => submitScore(false)}
        />
      </section>
    </>
  )
}

export default SubmitScore
